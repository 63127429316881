
.goal-resources-section{

  h3{
    font-size: 2rem;
    font-weight: bold;
    margin: 10px 0;
  }

  a{
    text-decoration: underline;
  }

}
